// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Berry" width="100" />
     *
     */
    <svg width="80" height="23" viewBox="0 0 80 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.543568 19.1405C0.404191 19.1405 0.278753 19.0917 0.167252 18.9941C0.0557505 18.8826 0 18.7502 0 18.5969V5.04951C0 4.89619 0.0557505 4.77075 0.167252 4.67319C0.278753 4.56169 0.404191 4.50594 0.543568 4.50594H6.64825C7.86083 4.50594 8.91312 4.68713 9.80513 5.04951C10.7111 5.41189 11.4149 5.96242 11.9167 6.70112C12.4184 7.43981 12.6693 8.36666 12.6693 9.48168C12.6693 10.5967 12.4184 11.5166 11.9167 12.2413C11.4149 12.9521 10.7111 13.4748 9.80513 13.8093C8.91312 14.1438 7.86083 14.3111 6.64825 14.3111H4.66214V18.5969C4.66214 18.7502 4.60639 18.8826 4.49489 18.9941C4.39732 19.0917 4.27188 19.1405 4.11857 19.1405H0.543568ZM4.57851 10.9451H6.54372C6.94791 10.9451 7.28938 10.8267 7.56814 10.5897C7.86083 10.3388 8.00717 9.95555 8.00717 9.43986C8.00717 9.04961 7.89567 8.6942 7.67267 8.37363C7.44967 8.05307 7.07335 7.89278 6.54372 7.89278H4.57851V10.9451Z" fill="url(#paint0_linear_14134_24171)"/>
    <path d="M14.7894 19.1405C14.636 19.1405 14.5036 19.0917 14.3921 18.9941C14.2946 18.8826 14.2458 18.7502 14.2458 18.5969V4.84044C14.2458 4.68713 14.2946 4.56169 14.3921 4.46413C14.5036 4.35263 14.636 4.29688 14.7894 4.29688H18.1762C18.3156 4.29688 18.441 4.35263 18.5525 4.46413C18.664 4.56169 18.7198 4.68713 18.7198 4.84044V9.41896C19.0821 9.00083 19.5351 8.67329 20.0787 8.43635C20.6362 8.18547 21.2634 8.06004 21.9603 8.06004C22.7129 8.06004 23.4028 8.23426 24.03 8.5827C24.6711 8.93114 25.1799 9.4538 25.5562 10.1507C25.9464 10.8476 26.1416 11.7256 26.1416 12.7849V18.5969C26.1416 18.7502 26.0858 18.8826 25.9743 18.9941C25.8628 19.0917 25.7374 19.1405 25.598 19.1405H22.1902C22.0369 19.1405 21.9045 19.0917 21.793 18.9941C21.6954 18.8826 21.6467 18.7502 21.6467 18.5969V12.9312C21.6467 12.4155 21.5212 12.0183 21.2704 11.7396C21.0195 11.4608 20.6641 11.3214 20.2041 11.3214C19.7442 11.3214 19.3818 11.4608 19.117 11.7396C18.8522 12.0183 18.7198 12.4155 18.7198 12.9312V18.5969C18.7198 18.7502 18.664 18.8826 18.5525 18.9941C18.441 19.0917 18.3156 19.1405 18.1762 19.1405H14.7894Z" fill="url(#paint1_linear_14134_24171)"/>
    <path d="M33.6468 19.3495C32.4203 19.3495 31.3819 19.1683 30.5317 18.806C29.6955 18.4436 29.0544 17.9139 28.6084 17.2171C28.1623 16.5062 27.9115 15.656 27.8557 14.6665C27.8418 14.3738 27.8348 14.0532 27.8348 13.7048C27.8348 13.3563 27.8418 13.0358 27.8557 12.7431C27.9115 11.7396 28.1763 10.8894 28.6502 10.1925C29.124 9.49561 29.7791 8.96598 30.6154 8.6036C31.4656 8.24123 32.476 8.06004 33.6468 8.06004C34.8176 8.06004 35.8211 8.24123 36.6573 8.6036C37.5075 8.96598 38.1696 9.49561 38.6435 10.1925C39.1173 10.8894 39.3821 11.7396 39.4379 12.7431C39.4658 13.0358 39.4797 13.3563 39.4797 13.7048C39.4797 14.0532 39.4658 14.3738 39.4379 14.6665C39.3821 15.656 39.1313 16.5062 38.6853 17.2171C38.2393 17.9139 37.5912 18.4436 36.741 18.806C35.9047 19.1683 34.8733 19.3495 33.6468 19.3495ZM33.6468 16.4017C34.1625 16.4017 34.5179 16.2484 34.713 15.9418C34.9082 15.6351 35.0197 15.1752 35.0475 14.5619C35.0615 14.3529 35.0684 14.0672 35.0684 13.7048C35.0684 13.3424 35.0615 13.0567 35.0475 12.8476C35.0197 12.2483 34.9082 11.7953 34.713 11.4887C34.5179 11.1681 34.1625 11.0078 33.6468 11.0078C33.1451 11.0078 32.7966 11.1681 32.6015 11.4887C32.4064 11.7953 32.2879 12.2483 32.2461 12.8476C32.2321 13.0567 32.2252 13.3424 32.2252 13.7048C32.2252 14.0672 32.2321 14.3529 32.2461 14.5619C32.2879 15.1752 32.4064 15.6351 32.6015 15.9418C32.7966 16.2484 33.1451 16.4017 33.6468 16.4017Z" fill="url(#paint2_linear_14134_24171)"/>
    <path d="M47.4033 19.1405C46.4555 19.1405 45.6192 19.0011 44.8945 18.7223C44.1837 18.4436 43.6262 17.9906 43.222 17.3634C42.8317 16.7223 42.6366 15.879 42.6366 14.8337V11.4678H40.985C40.8317 11.4678 40.6993 11.419 40.5878 11.3214C40.4902 11.2099 40.4414 11.0775 40.4414 10.9242V8.81267C40.4414 8.65935 40.4902 8.53392 40.5878 8.43635C40.6993 8.32485 40.8317 8.2691 40.985 8.2691H42.6366V4.84044C42.6366 4.68713 42.6854 4.56169 42.7829 4.46413C42.8944 4.35263 43.0268 4.29688 43.1802 4.29688H46.2116C46.3649 4.29688 46.4903 4.35263 46.5879 4.46413C46.6994 4.56169 46.7552 4.68713 46.7552 4.84044V8.2691H49.3894C49.5427 8.2691 49.6681 8.32485 49.7657 8.43635C49.8772 8.53392 49.9329 8.65935 49.9329 8.81267V10.9242C49.9329 11.0775 49.8772 11.2099 49.7657 11.3214C49.6681 11.419 49.5427 11.4678 49.3894 11.4678H46.7552V14.4783C46.7552 14.8825 46.8388 15.2031 47.006 15.44C47.1733 15.663 47.4381 15.7745 47.8005 15.7745H49.5566C49.7099 15.7745 49.8354 15.8303 49.9329 15.9418C50.0444 16.0393 50.1002 16.1648 50.1002 16.3181V18.5969C50.1002 18.7502 50.0444 18.8826 49.9329 18.9941C49.8354 19.0917 49.7099 19.1405 49.5566 19.1405H47.4033Z" fill="url(#paint3_linear_14134_24171)"/>
    <path d="M56.9726 17.4654C56.9726 17.6106 56.9198 17.736 56.8142 17.8416C56.7086 17.9472 56.5832 18 56.438 18H53.8444C53.6992 18 53.5738 17.9472 53.4682 17.8416C53.3626 17.736 53.3098 17.6106 53.3098 17.4654V14.8718C53.3098 14.7266 53.3626 14.6012 53.4682 14.4956C53.5738 14.39 53.6992 14.3372 53.8444 14.3372H56.438C56.5832 14.3372 56.7086 14.39 56.8142 14.4956C56.9198 14.6012 56.9726 14.7266 56.9726 14.8718V17.4654ZM66.696 4.14079C66.96 4.14079 67.158 4.21338 67.29 4.35858C67.4352 4.50377 67.5341 4.64896 67.5869 4.79415L71.9031 17.2674C71.9559 17.3994 71.9823 17.4984 71.9823 17.5644C71.9823 17.6832 71.9361 17.7888 71.8437 17.8812C71.7645 17.9604 71.6655 18 71.5467 18H69.0521C68.7881 18 68.5967 17.9472 68.4779 17.8416C68.3723 17.7228 68.3063 17.6106 68.2799 17.505L67.7255 15.8617H62.459L61.9047 17.505C61.8783 17.6106 61.8057 17.7228 61.6869 17.8416C61.5813 17.9472 61.3965 18 61.1325 18H58.6379C58.5191 18 58.4135 17.9604 58.3211 17.8812C58.2419 17.7888 58.2023 17.6832 58.2023 17.5644C58.2023 17.4984 58.2287 17.3994 58.2815 17.2674L62.5976 4.79415C62.6504 4.64896 62.7428 4.50377 62.8748 4.35858C63.02 4.21338 63.2246 4.14079 63.4886 4.14079H66.696ZM63.4886 12.7929H66.696L65.0923 8.04117L63.4886 12.7929ZM74.1161 18C73.9709 18 73.8455 17.9472 73.7399 17.8416C73.6343 17.736 73.5815 17.6106 73.5815 17.4654V4.67536C73.5815 4.53017 73.6343 4.40477 73.7399 4.29918C73.8455 4.19358 73.9709 4.14079 74.1161 4.14079H76.7097C76.8549 4.14079 76.9803 4.19358 77.0859 4.29918C77.1915 4.40477 77.2443 4.53017 77.2443 4.67536V17.4654C77.2443 17.6106 77.1915 17.736 77.0859 17.8416C76.9803 17.9472 76.8549 18 76.7097 18H74.1161Z" fill="black"/>
    <defs>
    <linearGradient id="paint0_linear_14134_24171" x1="4.67473" y1="-12.4772" x2="17.324" y2="46.9194" gradientUnits="userSpaceOnUse">
    <stop stop-color="#96E7F9"/>
    <stop offset="0.600865" stop-color="#E09EFF"/>
    <stop offset="1" stop-color="#8D34FF"/>
    </linearGradient>
    <linearGradient id="paint1_linear_14134_24171" x1="4.67473" y1="-12.4772" x2="17.324" y2="46.9194" gradientUnits="userSpaceOnUse">
    <stop stop-color="#96E7F9"/>
    <stop offset="0.600865" stop-color="#E09EFF"/>
    <stop offset="1" stop-color="#8D34FF"/>
    </linearGradient>
    <linearGradient id="paint2_linear_14134_24171" x1="4.67473" y1="-12.4772" x2="17.324" y2="46.9194" gradientUnits="userSpaceOnUse">
    <stop stop-color="#96E7F9"/>
    <stop offset="0.600865" stop-color="#E09EFF"/>
    <stop offset="1" stop-color="#8D34FF"/>
    </linearGradient>
    <linearGradient id="paint3_linear_14134_24171" x1="4.67473" y1="-12.4772" x2="17.324" y2="46.9194" gradientUnits="userSpaceOnUse">
    <stop stop-color="#96E7F9"/>
    <stop offset="0.600865" stop-color="#E09EFF"/>
    <stop offset="1" stop-color="#8D34FF"/>
    </linearGradient>
    </defs>
    </svg>
    
  );
};

export default Logo;
